
const Block_post = ({ title, image, image2, image3 }) => {


    return (
        <div className='w-full h-auto'>
            <div className="bggrad w-full h-[130px] text-center flex justify-center items-center mt-10">
                <h2 className="text-white">{title}</h2>
            </div>
            <div className="w-full flex justify-center items-center h-auto mt-10 space-x-5">
                <img src={image} alt="seguros" className="w-[w-auto h-[150px]" />
                <img src={image3} alt="seguros" className="w-[w-auto h-[150px]" />
                <img src={image2} alt="seguros" className="w-[w-auto h-[150px]" />
            </div>
        </div>

    )
}


export default Block_post;